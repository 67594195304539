import React, { useState, useEffect, useRef, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Context } from "../../contexts/Store";
//components
import ChiefSidebar from "./ChiefSidebar";
import { db } from "../../../fiebase";
import { accountsConfig } from "../../../axiosConfig";

function SideBar() {
    const [isExpand, setExpand] = useState(false);
    const [isSelected, setSelected] = useState(false);
    const [isSubMenu, setSubMenu] = useState(false);
    const [isSyllabusMenu, setSyllabusMenu] = useState(false);
    const [isSubCommunity, setSubCommunity] = useState(false);
    const [isJobDesk, setSubJobDeskMenu] = useState(false);

    const { state } = useContext(Context);
    const { support_sidebar } = state;
    const role = state.user_data.role[0];
    const {
        state: {
            user_data: { access_token },
        },
    } = useContext(Context);

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setExpand(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    //  <----- Logout User ----->
    const logoutUser = (e) => {
        e.preventDefault();
        accountsConfig
            .post(
                "/authentication/logout/",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    localStorage.clear();
                    window.location = "/";
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    async function makeOffline() {
        const docRef = doc(db, "users", state.user_data.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            updateDoc(doc(db, "users", state.user_data.uid), {
                isOnline: false,
            });
        }
        localStorage.clear();
        window.location = "/";
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);
    function showSideMenu() {
        let element = document.getElementById("menu");
        element.classList.toggle("active");
    }

    useEffect(() => {
        $(".menu-item").click(function () {
            $(this);
            if ($(this).parent().hasClass("sub-menu")) {
                $(this).parent().find(".drop-down-menu").slideToggle("fast");
            }

            $(".drop-down-menu")
                .not($(this).parent().children())
                .slideUp("fast");
        });
    }, []);

    return (
        <>
            <Container
                id="menu"
                ref={wrapperRef}
                className={isExpand && "expand"}
                onMouseEnter={() => setExpand(true)}
                onMouseLeave={() => setExpand(false)}
                type={support_sidebar ? "chat" : ""}
            >
                <SideButtons>
                    <ImageContainer onClick={() => showSideMenu()}>
                        <LogoLink to="/">
                            <Logo
                                className="thumb"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/15-09-2021/steyp-logo.svg"
                                }
                                alt="talrop-logo"
                            />
                        </LogoLink>
                    </ImageContainer>
                    {role === "campus" && (
                        <ChiefSidebar
                            isJobDesk={isJobDesk}
                            setSubJobDeskMenu={setSubJobDeskMenu}
                            isSubCommunity={isSubCommunity}
                            setSubCommunity={setSubCommunity}
                            isSubMenu={isSubMenu}
                            setSubMenu={setSubMenu}
                            isSyllabusMenu={isSyllabusMenu}
                            setSyllabusMenu={setSyllabusMenu}
                            isSelected={isSelected}
                            setSelected={setSelected}
                            isExpand={isExpand}
                            wrapperRef={wrapperRef}
                            setExpand={setExpand}
                        />
                    )}
                </SideButtons>
                <Logout
                    to="/auth/logout/"
                    onClick={(e) => {
                        // makeOffline();
                        e.preventDefault();
                        localStorage.clear();
                        window.location = "/";
                    }}
                >
                    <MenuItemIcon
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/yiaai/01-02-2022/icons/logout.svg"
                        alt="Icon"
                    />
                    <Title>Logout</Title>
                </Logout>
            </Container>
        </>
    );
}

const Title = styled.span`
    font-family: "gordita_regular";
    color: inherit;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    white-space: nowrap;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #f7684c;
    }
`;
const SideButtons = styled.div``;
const SubTitle = styled.p`
    font-family: "gordita_medium";
    font-size: 14px;
    white-space: nowrap;
    color: #fff;
    @media only screen and (max-width: 1550px) {
        display: none;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    &.selected {
        color: #1ec569;
    }
`;
const Menu = styled.div`
    padding: 10px 21px;
    display: flex;
    align-items: baseline;
    width: 100%;
    position: relative;
    transition: all 0.4s ease;
`;

const MenuItemIcon = styled.img`
    display: block;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const MenuItemIcon1 = styled.img`
    display: none;
    width: 16px;
    margin-right: 1.125rem;
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const ArrowImageContainer = styled.img`
    display: block;
    width: 16px;
    transform: rotate(-90deg);
    transition: all 0.4s ease;
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;

const ArrowImageContainer1 = styled.img`
    display: none;
    width: 16px;
    /* margin-right: 1.125rem; */
    transform: rotate(0deg);
    @media only screen and (max-width: 1550px) {
        margin-right: 0;
    }
    @media only screen and (max-width: 768px) {
        margin-right: 1.125rem;
    }
`;
const DownIcon = styled.img``;
const DownIcon1 = styled.img``;

const Button = styled(NavLink)`
    width: -webkit-fill-available;
    height: 46px;
    display: flex;
    color: #fff;
    transition: all 0.4s ease;
    &.active {
        border-left: 5px solid #fe673a;
        color: #1ec569 !important;
        background: #161616;
        ${MenuItemIcon} {
            display: none;
        }
        ${MenuItemIcon1} {
            display: block;
        }
        ${DownIcon} {
            display: none;
        }
        ${DownIcon1} {
            display: block;
        }

        ${Title} {
            color: #f7684c;
        }
        ${ArrowImageContainer} {
            display: none;
        }
        ${ArrowImageContainer1} {
            display: block;
        }
    }
`;

const Container = styled.aside`
    background: #1b1c1f;
    width: ${({ type }) => (type === "chat" ? "54px " : "218px")};
    position: fixed;
    z-index: 300;
    top: 70px;
    left: 0;
    transition: all 0.4s ease;
    height: calc(100vh - 80px);
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    &::-webkit-scrollbar {
        display: none;
    }
    @media only screen and (max-width: 1550px) {
        width: 49px;
        overflow: hidden;
    }
    @media only screen and (max-width: 768px) {
        top: 60px;
        transform: translateX(-220px);
        width: 220px;
        &.active {
            transform: translateX(0);
        }
    }
    &.expand {
        width: 218px;
        transition: all 0.4s ease;
        ${Title} {
            display: block;
        }
        ${SubTitle} {
            display: block;
        }
        ${Menu} {
        }
        ${MenuItemIcon} {
            margin-right: 1.125rem;
        }
        ${MenuItemIcon1} {
            margin-right: 1.125rem;
        }
        ${Button} {
        }
    }
`;

const ImageContainer = styled.h1`
    width: 152px;
    margin-right: 92px;
    display: none;
    margin: 0 auto;
    margin: 32px;
    @media only screen and (max-width: 1280px) {
        width: 147px;
        margin-right: 58px;
    }
    @media only screen and (max-width: 768px) {
        display: block;
    }
    @media only screen and (max-width: 640px) {
        width: 100px;
    }
`;
const LogoLink = styled(Link)`
    display: block;
`;
const Logo = styled.img`
    display: block;
    width: 100%;
`;
const Overlay = styled.div``;
const Logout = styled(Link)`
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
    padding-bottom: 10px;
`;

export default SideBar;
