import React, { useState, useEffect, useRef, useContext } from "react";
import styled, { keyframes } from "styled-components";
import $ from "jquery";
import CountryModal from "./CountryModal";
import phoneImage from "../../../../assets/images/subscription_manager/phone.svg";
import round from "../../../../assets/images/subscription_manager/round.svg";
import tick from "../../../../assets/images/subscription_manager/tick.svg";
import show from "../../../../assets/images/subscription_manager/show.svg";
import DownArrow from "../../../../assets/images/subscription_manager/DownAroow.svg";
import { Context } from "../../../contexts/Store";
import { accountsConfig } from "../../../../axiosConfig";
import ButtonLoader from "../../../genaral/Loader/ButtonLoader";
import SuccessModal from "./SuccessModal";
import UserVerifyModal from "./UserVerifyModal";

function CreateStudentModal({
    isModal,
    setModal,
    isReload,
    setReload,
    upload,
    defaultStudent,
}) {
    const [activeTab, setActiveTab] = useState(0);
    const [hide, setHide] = useState(true);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(100);
    const [field, setField] = useState("");
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [className, setClassName] = useState("");
    const [divisionName, setDivisionName] = useState("");

    const [password, setPassword] = useState("");
    const [visible, setVisible] = useState(false);
    const [countryselector, setCountryselector] = useState(false);
    const [isError, setError] = useState(false);
    const [isLoading, setLoading] = useState("");
    const [ResponseError, setResponseError] = useState("");
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [uploadFile, setUploadFile] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [confirm, setConfirm] = useState(false);
    const [existingData, setExistingData] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [changePassword, setChangePassword] = useState(false);


    const generatePassword = () => {
        const lowercase = 'abcdefghijklmnopqrstuvwxyz';
        const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';
        const symbols = '$@#!*&?%';
        
        const getRandomChar = (characters) => {
          return characters.charAt(Math.floor(Math.random() * characters.length));
        };
        
        let generatedPassword = '';
        let conditionsMet = false;
        
        while (!conditionsMet) {
          generatedPassword = '';
          
          generatedPassword += getRandomChar(lowercase); 
          generatedPassword += getRandomChar(uppercase);
          generatedPassword += getRandomChar(numbers);
          
        
          generatedPassword += getRandomChar(symbols);
        
         
          for (let i = 0; i < 4; i++) {
            const allCharacters = lowercase + uppercase + numbers + symbols;
            generatedPassword += getRandomChar(allCharacters);
          }
        
          
          generatedPassword = generatedPassword.split('').sort(() => Math.random() - 0.5).join('');
        
          // Check if all conditions are met
          conditionsMet = /[a-z]/.test(generatedPassword) &&
                          /[A-Z]/.test(generatedPassword) &&
                          /[0-9]/.test(generatedPassword) &&
                          /[$@#%!*&?]/.test(generatedPassword);
        }
        
        setPassword(generatedPassword);
      };
      

    const { state } = useContext(Context);
    const {
        state: {
            user_data: { access_token },
            selectedEditingMyProfileData,
        },
    } = useContext(Context);
    const { selected_country } = state;

    const [selectedCountry, setSelectedCountry] = useState(selected_country);
    console.log(selectedCountry, "--selectedCountry");

    useEffect(() => {
        if (defaultStudent) {
            setPhone(defaultStudent.phone);
            setName(defaultStudent.name);
        }
    }, [defaultStudent]);
    /// out side click
    useEffect(() => {
        if (isModal) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [isModal]);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    const handleShow = () => {
        setCountryselector((prevValue) => !prevValue);
    };
    const onSelectHandler = (selected) => {
        setSelectedCountry(selected);
    };

    // -------------- close modal -------------------------

    const handleClose = () => {
        setName("");
        setPhone("");
        setPassword("");
        setLoading("");
        setModal(false);
        setUploadFile(null);
        setFilePreview(null);
        setFileName("");
        setFileSize("");
        setResponseError("");
        setClassName("");
        setDivisionName("");
    };

    //-------------- File upload handles --------------------

    const handleUpload = async () => {
        setLoading("upload-image");
        setUploading(true);
        const uploadFormData = new FormData();
        uploadFormData.append("file", uploadFile);

        try {
            const response = await accountsConfig.post(
                "/api/v1/users/techatschool-upload-student-details/",
                uploadFormData,
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            );

            const { StatusCode, data } = response.data;
            if (StatusCode === 6000) {
                setUploading(false);
                setSuccessModal(true);
                setModal(false);
                setFilePreview(null);
                setReload(true);
                setResponseError("");
                setLoading("");
            } else if (StatusCode === 6001) {
                setUploading(false);
                setLoading("");
                setResponseError(response.data.message);
            }
        } catch (error) {
            console.error(error);
            setUploading(false);
            alert("Error uploading file");
        }
    };

    const handleFile = (selectedFile) => {
        if (selectedFile) {
            setUploadFile(selectedFile);
            setFilePreview(null);

            const reader = new FileReader();

            reader.onloadend = () => {
                setFilePreview(reader.result);
            };

            reader.readAsDataURL(selectedFile);

            setFileName(selectedFile.name);

            const fileSizeInMb = (selectedFile.size / (1024 * 1024)).toFixed(2);
            setFileSize(fileSizeInMb);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files[0];
        handleFile(selectedFile);
    };

    const handleInputChange = (e) => {
        const selectedFile = e.target.files[0];
        handleFile(selectedFile);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const clearFileState = () => {
        setUploadFile(null);
        setFilePreview(null);
        setFileName("");
        setFileSize("");
    };

    //---------- find already existing  student account -----------

    const handleFindAccount = async () => {
        setLoading("submitting");
        const response = await accountsConfig
            .post(
                `api/v1/users/techatschool/check-profile/`,
                {
                    name,
                    password,
                    phone,
                    country: selectedCountry.web_code,
                    service: "learn",
                },
                {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                    },
                }
            )
            .then((response) => {
                const { StatusCode, data, message } = response.data;
                if (StatusCode === 6000) {
                    handleSubmit(false);
                    setLoading("");
                } else if (StatusCode === 6001) {
                    setConfirm(true);
                    setExistingData(data);
                    setModal(false);
                    setLoading("");
                } else {
                    setError(true);
                    setErrorMessage(message.message);
                    setLoading("");
                }
            });
    };

    //--------------- create student single ----------------

    const handleSubmit = async (change_password) => {
        try {
            setError(true);
            setLoading("submitting");
            setResponseError("");

            if (name && phone && password) {
                const response = await accountsConfig.post(
                    `api/v1/users/techatschool/create-student/`,
                    {
                        name,
                        password,
                        phone,
                        country: selectedCountry?.web_code,
                        change_password: change_password,
                        student_class: className,
                        division: divisionName,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                        },
                    }
                );

                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setError(false);
                    setName("");
                    setPhone("");
                    setPassword("");
                    setLoading("");
                    setModal(false);
                    setConfirm(false);
                    setSuccessModal(true);
                } else if (StatusCode === 6001) {
                    setLoading("");
                    setResponseError(response.data.message);
                    setError(true);
                }
            } else {
                setError(true);
                setLoading("");
                setResponseError("Please fill in all required fields.");
            }
        } catch (error) {
            console.error("Error creating student:", error);
            setError(false);
            setLoading("");
        }
    };

    function useOutsideClick(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setModal(false);
                    setField("");
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef);

    return (
        <BackContainer>
            {/* <Overlay onClick={handleModal}></Overlay> */}
            {isSuccessModal ? (
                <SuccessModal
                    isSuccessModal={isSuccessModal}
                    setSuccessModal={setSuccessModal}
                    isReload={isReload}
                    setReload={setReload}
                    setModal={setModal}
                    isModal={isModal}
                    sucessText={"Student Created Successfully"}
                />
            ) : (
                ""
            )}
            {confirm ? (
                <UserVerifyModal
                    isModal={confirm}
                    setModal={setConfirm}
                    existingData={existingData}
                    setChangePassword={setChangePassword}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    setLoading={setLoading}
                />
            ) : (
                ""
            )}
            {isModal ? (
                <Overlay
                    onClick={() => setModal(false)}
                    style={{ display: isModal ? "block" : "none" }}
                ></Overlay>
            ) : (
                ""
            )}
            <Cover style={{ transform: isModal && "scale(1,1)" }}>
                <Modal ref={wrapperRef}>
                    <Container>
                        <Header>
                            <Title>
                                <TitleImage>
                                    <img
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2023/admin.svg"
                                        alt="Admission"
                                    />
                                </TitleImage>
                                <Headtitle>Add Student</Headtitle>
                                <CountryModal
                                    show={countryselector}
                                    setShows={setCountryselector}
                                    handleClick={handleShow}
                                    onSelectHandler={onSelectHandler}
                                    selectedCountry={selectedCountry}
                                    selectedwebCode={
                                        selectedEditingMyProfileData?.country
                                            ?.web_code &&
                                        selectedEditingMyProfileData?.country
                                            ?.web_code
                                    }
                                />
                            </Title>
                            <Closeicon onClick={() => handleClose()}>
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                    alt="Icon"
                                />
                            </Closeicon>
                        </Header>
                        {/* <TabWrapper>
                            <TabLabel
                                active={activeTab === 0}
                                onClick={() => handleTabClick(0)}
                            >
                                Add Student
                            </TabLabel>

                            {upload && (
                                <TabLabel
                                    active={activeTab === 1}
                                    onClick={() => handleTabClick(1)}
                                >
                                    Import XLSX
                                </TabLabel>
                            )}
                        </TabWrapper> */}
                        <TabContent>
                            {activeTab === 0 ? (
                                <Middle>
                                    <Data>
                                        <SelectData>
                                            <SelectDataTitle>
                                                Phone Number *
                                            </SelectDataTitle>
                                            <ContactSection>
                                                <CountrySelect
                                                    onClick={() =>
                                                        setCountryselector(
                                                            !countryselector
                                                        )
                                                    }
                                                >
                                                    <LeftCountrydiv>
                                                        <img
                                                            src={
                                                                selectedCountry.flag
                                                            }
                                                            alt="image"
                                                        />
                                                    </LeftCountrydiv>
                                                    <RightCountrydiv>
                                                        <img
                                                            src={DownArrow}
                                                            alt="image"
                                                        />
                                                    </RightCountrydiv>
                                                </CountrySelect>

                                                <Contact
                                                    onClick={() =>
                                                        setField("phone")
                                                    }
                                                    className={
                                                        field === "phone"
                                                            ? "border"
                                                            : ""
                                                    }
                                                >
                                                    <PhoneImage>
                                                        <img
                                                            src={phoneImage}
                                                            alt="image"
                                                        />
                                                    </PhoneImage>
                                                    <span>
                                                        {
                                                            selectedCountry.phone_code
                                                        }
                                                    </span>
                                                    <NumberInput
                                                        type="number"
                                                        placeholder="Enter Phone Number"
                                                        value={phone}
                                                        onKeyDown={(e) => {
                                                            const isNumeric =
                                                                /^[0-9]+$/.test(
                                                                    e.key
                                                                );

                                                            const isSpecialKey =
                                                                [
                                                                    "Backspace",
                                                                    "Delete",
                                                                    "ArrowLeft",
                                                                    "ArrowRight",
                                                                ].includes(
                                                                    e.key
                                                                );

                                                            if (
                                                                !isNumeric &&
                                                                !isSpecialKey
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            let enteredValue =
                                                                e.target.value;
                                                            if (
                                                                enteredValue.length >
                                                                10
                                                            ) {
                                                                enteredValue =
                                                                    enteredValue.slice(
                                                                        0,
                                                                        10
                                                                    );
                                                            }
                                                            setPhone(
                                                                enteredValue
                                                            );
                                                            setErrorMessage("");
                                                        }}
                                                    />
                                                </Contact>
                                            </ContactSection>
                                        </SelectData>
                                        {isError === true && phone === "" && (
                                            <Error className="error">
                                                This field is required
                                            </Error>
                                        )}
                                        {ResponseError !== "" && (
                                            <ErrorMsg>
                                                {ResponseError.message ===
                                                "Invalid phone number" ? (
                                                    <span>
                                                        Invalid phone number
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </ErrorMsg>
                                        )}
                                    </Data>
                                    <Phone>
                                        <SelectData>
                                            <SelectDataTitle>
                                                Full Name *
                                            </SelectDataTitle>
                                            <SelectExaminerInput
                                                onClick={() => setField("name")}
                                                onChange={(e) => {
                                                    const enteredValue =
                                                        e.target.value;
                                                    const formattedValue =
                                                        enteredValue
                                                            .toLowerCase()
                                                            .split(" ")
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.slice(
                                                                        1
                                                                    )
                                                            )
                                                            .join(" ");

                                                    setName(formattedValue);
                                                }}
                                                className={
                                                    field === "name"
                                                        ? "border"
                                                        : ""
                                                }
                                                type="text"
                                                placeholder="Enter Full Name"
                                                value={name}
                                                autoComplete="off"
                                            />
                                        </SelectData>
                                        {isError === true && name === "" && (
                                            <Error className="error">
                                                This field is required
                                            </Error>
                                        )}
                                    </Phone>

                                    <SelectTextArea>
                                        <SelectDataTitle>
                                            Password *
                                        </SelectDataTitle>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "8px",
                                            }}
                                        >
                                            <PasswordDiv
                                                onClick={() =>
                                                    setField("password")
                                                }
                                                className={
                                                    field === "password"
                                                        ? "border"
                                                        : ""
                                                }
                                            >
                                                <PasswordInput
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onClick={() =>
                                                        setField("password")
                                                    }
                                                    className={
                                                        field === "password"
                                                            ? "border"
                                                            : ""
                                                    }
                                                    type={
                                                        visible
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    placeholder="Enter Password"
                                                    autoComplete="new-password"
                                                    maxLength={30}
                                                />
                                                <Eyediv
                                                    onClick={() =>
                                                        setVisible(!visible)
                                                    }
                                                >
                                                    <img
                                                        src={show}
                                                        alt="image"
                                                    />
                                                </Eyediv>
                                            </PasswordDiv>
                                            <PassWordButton
                                                onClick={generatePassword}
                                            >
                                                Generate
                                            </PassWordButton>
                                        </div>
                                    </SelectTextArea>
                                    <Phone>
                                        <SelectData>
                                            <SelectDataTitle>
                                                Class
                                            </SelectDataTitle>
                                            <SelectExaminerInput
                                                onClick={() =>
                                                    setField("class")
                                                }
                                                onChange={(e) => {
                                                    const enteredValue =
                                                        e.target.value;
                                                    const formattedValue =
                                                        enteredValue
                                                            .toLowerCase()
                                                            .split(" ")
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.slice(
                                                                        1
                                                                    )
                                                            )
                                                            .join(" ");

                                                    setClassName(
                                                        formattedValue
                                                    );
                                                }}
                                                className={
                                                    field === "class"
                                                        ? "border"
                                                        : ""
                                                }
                                                type="text"
                                                placeholder="Enter Class"
                                                value={className}
                                                autoComplete="off"
                                            />
                                        </SelectData>
                                    </Phone>

                                    <Phone>
                                        <SelectData>
                                            <SelectDataTitle>
                                                Division
                                            </SelectDataTitle>
                                            <SelectExaminerInput
                                                onClick={() =>
                                                    setField("division")
                                                }
                                                onChange={(e) => {
                                                    const enteredValue =
                                                        e.target.value;
                                                    const formattedValue =
                                                        enteredValue
                                                            .toLowerCase()
                                                            .split(" ")
                                                            .map(
                                                                (word) =>
                                                                    word
                                                                        .charAt(
                                                                            0
                                                                        )
                                                                        .toUpperCase() +
                                                                    word.slice(
                                                                        1
                                                                    )
                                                            )
                                                            .join(" ");

                                                    setDivisionName(
                                                        formattedValue
                                                    );
                                                }}
                                                className={
                                                    field === "division"
                                                        ? "border"
                                                        : ""
                                                }
                                                type="text"
                                                placeholder="Enter Division"
                                                value={divisionName}
                                                autoComplete="off"
                                            />
                                        </SelectData>
                                    </Phone>
                                    {password.length > 0 && (
                                        <Validation>
                                            <List>
                                                <ListImage>
                                                    {password.length > 7 ? (
                                                        <img
                                                            src={tick}
                                                            alt="image"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={round}
                                                            alt="image"
                                                        />
                                                    )}
                                                </ListImage>
                                                <span>
                                                    Should contain at least 8
                                                    characters
                                                </span>
                                            </List>
                                            <List>
                                                <ListImage>
                                                    {password.match(/[a-z]/) ? (
                                                        <img
                                                            src={tick}
                                                            alt="image"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={round}
                                                            alt="image"
                                                        />
                                                    )}
                                                </ListImage>
                                                <span>
                                                    Should contain a lowercase
                                                    (small) letter (a-z)
                                                </span>
                                            </List>
                                            <List>
                                                <ListImage>
                                                    {password.match(/[A-Z]/) ? (
                                                        <img
                                                            src={tick}
                                                            alt="image"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={round}
                                                            alt="image"
                                                        />
                                                    )}
                                                </ListImage>
                                                <span>
                                                    Should contain a uppercase
                                                    (capital) letter (A - Z)
                                                </span>
                                            </List>
                                            <List>
                                                <ListImage>
                                                    {password.match(/[0-9]/) ? (
                                                        <img
                                                            src={tick}
                                                            alt="image"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={round}
                                                            alt="image"
                                                        />
                                                    )}{" "}
                                                </ListImage>
                                                <span>
                                                    Should contain at least one
                                                    number (0-9)
                                                </span>
                                            </List>
                                            <List>
                                                <ListImage>
                                                    {password.match(
                                                        /[$,@,#,!,*,?,&,%]/
                                                    ) ? (
                                                        <img
                                                            src={tick}
                                                            alt="image"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={round}
                                                            alt="image"
                                                        />
                                                    )}
                                                </ListImage>
                                                <span>
                                                    Should contain at least one
                                                    symbol ($,@,#,%,!,*,?,&)
                                                </span>
                                            </List>
                                        </Validation>
                                    )}
                                    {password.length > 7 &&
                                    password.match(/[a-z]/) &&
                                    password.match(/[A-Z]/) &&
                                    password.match(/[0-9]/) &&
                                    password.match(/[$,@,#,!,%,*,?,&]/) ? (
                                        <>
                                            {errorMessage && (
                                                <Error className="error error-message">
                                                    {errorMessage}
                                                </Error>
                                            )}
                                            <CreateButtonBox>
                                                <CreateButton
                                                    onClick={handleFindAccount}
                                                >
                                                    {isLoading ===
                                                    "submitting" ? (
                                                        <ButtonLoader />
                                                    ) : (
                                                        "Create"
                                                    )}
                                                </CreateButton>
                                            </CreateButtonBox>
                                        </>
                                    ) : (
                                        <CreateButtonBox>
                                            <CreateButton className="disabled">
                                                Create
                                            </CreateButton>
                                        </CreateButtonBox>
                                    )}
                                </Middle>
                            ) : (
                                <>
                                    <FormBox
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                    >
                                        <UploadInput
                                            type="file"
                                            id="fileUpload"
                                            onChange={handleInputChange}
                                        />
                                        <LabelWrapper
                                            active={true}
                                            htmlFor="fileUpload"
                                        >
                                            {uploading ? (
                                                <LoadingSpinner>
                                                    <Spinner>
                                                        <SpinnerInner />
                                                    </Spinner>
                                                    <LoadingText>
                                                        {uploadProgress}%
                                                    </LoadingText>
                                                </LoadingSpinner>
                                            ) : (
                                                <>
                                                    <BrowseIcon>
                                                        <img
                                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2023/browse-icon.svg"
                                                            alt="File"
                                                        />
                                                    </BrowseIcon>
                                                    <BrowseLabel1>
                                                        Drag and Drop file here.
                                                    </BrowseLabel1>
                                                    <BrowseLabel>
                                                        Browse file
                                                    </BrowseLabel>
                                                </>
                                            )}
                                            {filePreview && (
                                                <>
                                                    <FileName>
                                                        {fileName}
                                                    </FileName>
                                                    <FileSize>
                                                        {fileSize} MB
                                                    </FileSize>
                                                </>
                                            )}
                                        </LabelWrapper>
                                        {ResponseError !== "" && (
                                            <ErrorMsg>
                                                {ResponseError.message ===
                                                "'file' - this key is required" ? (
                                                    "Please select a file"
                                                ) : (
                                                    <span>
                                                        {ResponseError.message}
                                                    </span>
                                                )}
                                            </ErrorMsg>
                                        )}
                                        <CreateButtonBox>
                                            <CreateButton
                                                onClick={handleUpload}
                                            >
                                                {isLoading ? (
                                                    <ButtonLoader />
                                                ) : (
                                                    "Create"
                                                )}
                                            </CreateButton>
                                        </CreateButtonBox>
                                    </FormBox>
                                </>
                            )}
                        </TabContent>
                    </Container>
                </Modal>
            </Cover>
        </BackContainer>
    );
}

export default CreateStudentModal;

const UploadInput = styled.input`
    display: none;
`;

const Cover = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
    cursor: auto;
`;
const FormBox = styled.div``;

const BackContainer = styled.div``;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 1000;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);
`;
const Modal = styled.div`
    width: 90%;
    max-width: 600px;
    max-height: 100vh;
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
    z-index: 101;
    border: 1px solid #26272a;
    background: #1b1c1f;
    border-radius: 5px;
    overflow-y: hidden;
    box-shadow: 0px 3px 56px #00000048;
    @media all and (max-width: 1280px) {
        max-height: 93vh;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 24px; */
    padding: 24px 35px;
    width: 100%;
    height: 100%;
    cursor: auto;
    @media all and (max-width: 768px) {
        padding: 30px;
    }
    @media all and (max-width: 480px) {
        width: 500px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const Header = styled.div`
    border-bottom: 2px solid #26272a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;
`;
const Head = styled.div`
    border-bottom: 2px solid #26272a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
`;
const Headtitle = styled.p`
    font-size: 18px;
    margin-left: 10px;
    color: #fff;
    font-family: gordita_medium;
`;
const Closeicon = styled.div`
    width: 18px;
    cursor: pointer;
    img {
        width: 100%;
        display: block;
    }
`;

const Middle = styled.div`
    /* margin-top: 10px; */
`;

const Data = styled.div`
    margin-top: 15px;
    position: relative;
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const SelectData = styled.div`
    position: relative;
    width: 100%;

    @media all and (max-width: 480px) {
        margin-top: 15px;
        width: 100%;
    }
`;
const SelectDataTitle = styled.p`
    font-size: 14px;
    font-family: "gordita_regular";
    color: #fff;
    margin-bottom: 5px;
`;
const SelectExaminerInput = styled.input`
    background-color: #161619 !important;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    padding: 10px;
    height: 45px;

    color: #fff;
    ::-webkit-calendar-picker-indicator {
        display: block;
        width: 15px;
        background-size: cover;
    }
    input::-webkit-input-placeholder {
        color: #9b9a9a;
        font-size: 14px;
    }
    input:-moz-placeholder {
        color: #9b9a9a;
    }
    &.border {
        border: 1px solid #fe673a;
    }
`;
const Phone = styled.div`
    margin-top: 15px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const Error = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 3px;
    &.error-message {
        margin-top: 18px;
    }
`;
const CreateButton = styled.button`
    background: #fe673a;
    color: #fff;
    border-radius: 5px;
    width: 120px;
    height: 40px;
    justify-content: center;
    display: flex;
    font-size: 14px;
    align-items: center;
    cursor: pointer;
    font-family: "gordita_medium";
    &.disabled {
        cursor: not-allowed;
    }
`;
const CreateButtonBox = styled.div`
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;

    @media all and (max-width: 480px) {
        margin-top: 15px;
    }
`;

const SelectAreaInput = styled.input`
    width: 100%;
    height: 80px;
    resize: none;
    background-color: #161619 !important;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    color: #fff;
    font-size: 14px;
    padding: 10px;
`;
const SelectTextArea = styled.div`
    width: 100%;
    margin-top: 15px;
    position: relative;
    img {
        position: absolute;
        right: 0;
    }
`;
const Title = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: center;
`;
const TitleImage = styled.div`
    width: 28px;
    height: 24px;
    img {
        width: 100%;
        display: block;
    }
`;
const Contact = styled.div`
    background-color: #161619 !important;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    height: 45px;
    &.border {
        border: 1px solid #fe673a;
    }

    span {
        font-family: "gordita_medium";
        font-size: 14px;
        color: #fff;
        margin-left: 8px;
    }
`;
const NumberInput = styled.input`
    color: #fff;
    margin-left: 5px;
    width: 100%;
    input::-webkit-input-placeholder {
        color: #bcbcbc;
        font-size: 14px;
    }
    input:-moz-placeholder {
        color: #bcbcbc;
    }
`;
const PhoneImage = styled.div`
    img {
        width: 100%;
        display: block;
    }
    width: 15px;
`;
const Validation = styled.div`
    margin-top: 15px;
    & :first-child {
        margin-top: 0;
    }
`;
const List = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
    span {
        font-size: 13px;
        color: #fff;
        margin-left: 10px;
        display: inline-block;
        font-family: "gordita_regular";
    }
`;
const ListImage = styled.div`
    width: 15px;
    img {
        width: 100%;
        display: block;
    }
`;
const PasswordDiv = styled.div`
    background-color: #161619 !important;
    border: 1px solid #26272a;
    border-radius: 5px !important;
    width: 100%;
    padding: 10px;
    height: 45px;
    position: relative;
    &.border {
        border: 1px solid #fe673a;
    }
`;
const PasswordInput = styled.input`
    color: #fff;
    height: 100%;
    width: 100%;
`;
const Eyediv = styled.div`
    width: 20px;
    position: absolute;
    right: 10px;
    bottom: 70%;
    img {
        width: 100%;
        display: block;
    }
`;
const CountrySelect = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
`;
const LeftCountrydiv = styled.div`
    border-radius: 50%;
    overflow: hidden;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;

    img {
        width: 100%;
        display: block;
        object-fit: cover;
    }
`;

const RightCountrydiv = styled.div`
    width: 20px;

    img {
        width: 100%;
        display: block;
    }
`;
const ContactSection = styled.div`
    display: flex;
`;
const TabWrapper = styled.div`
    display: flex;
    width: 50%;
`;

const TabLabel = styled.span`
    flex: 1;
    text-align: left;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    color: ${(props) => (props.active ? "#E76037" : "")};
    border-bottom: 2px solid
        ${(props) => (props.active ? "#E76037" : "#26272a")};
    transition: border-color 0.3s ease;
    &:hover {
        opacity: 90%;
    }
`;
const TabContent = styled.div`
    /* padding: 20px 0; */
    background: #1b1c1f;
`;
const LabelWrapper = styled.label`
    display: flex;
    height: 324px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #2c2c2c;
    background: #161619;
    cursor: pointer;
`;
const Button = styled.button`
    color: #fff;
    cursor: pointer;
    background: #525252;
    width: fit-content;
    padding: 10px 36px;
    display: inline-block;
    border-radius: 4px;
    &:hover {
        opacity: 80%;
    }
`;
const CancelButton = styled.button`
    color: #fff;
    cursor: pointer;
    width: fit-content;
    border: 1px solid #fe673a;
    padding: 9px 36px;
    display: inline-block;
    border-radius: 4px;
    margin-right: 12px;
    &:hover {
        opacity: 80%;
    }
`;
const FormBtnDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 24px;
`;
const EyeSpan = styled.span`
    max-width: 25px;
    max-height: 25px;
    display: block;
    position: absolute;
    right: 20px;
    top: 54px;
`;
const Eye = styled.img`
    max-width: 100%;
    display: block;
`;
const InputField = styled.input`
    width: 100%;
    font-size: 18px;
    color: #fff;
    font-family: "gordita_regular";
    margin-top: 12px;

    &:focus-within {
        border-color: #fe673a;
    }
    @media (max-width: 480px) {
        font-size: 16px;
    }
`;
const UserInput = styled.div`
    position: relative;
`;
const BrowseIcon = styled.div`
    img {
        width: 100%;
        display: block;
    }
`;
const BrowseLabel1 = styled.h6`
    color: #9f9f9f;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
`;
const BrowseLabel = styled.h5`
    background: #161619;
    color: #fe673a;
    font-size: 16px;
    text-decoration-line: underline;
    cursor: pointer;
`;
const ReceivedFiles = styled.div``;
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #e76037;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: ${spin} 1s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SpinnerInner = styled.div`
    border: 10px solid rgba(0, 0, 0, 0.1);
    border-top: 10px solid #e76037;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: ${spin} 3s linear infinite;
`;

const LoadingText = styled.div`
    margin-top: 10px;
`;
const ErrorMsg = styled.p`
    color: red;
    font-size: 14px;
    margin-top: 4px;
`;
const FilePreview = styled.div`
    max-width: 100%;
    max-height: 150px;
    margin-top: 10px;
`;

const PassWordButton = styled.button`
    border: 1px solid #26272a;
    cursor: pointer;
    padding: 8px;
    height: 45px;
    color: #fff;
    border-radius: 6px;
    margin-top: 4px;
    font-family: "gordita_medium";
    font-size: 14px;
`;

const FileName = styled.p`
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
`;
const FileSize = styled.p`
    font-size: 12px;
    color: #9f9f9f;
`;
