import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import StudentRouter from "../students/StudentRouter";
import EnquiryRouter from "../enquiry/EnquiryRouter";
import Payments from "../../../screens/payments/Payments";
import EnquiesRouter from "../students/EnquiesRouter";

export default function ChiefRouter() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/students/" />} />
            <Route path="/students/*" element={<StudentRouter />} />
            <Route path="/exams/" element={<EnquiryRouter />} />
            <Route path="/enquiries/" element={<EnquiesRouter />} />
            {/* <Route path="/payments/" element={<Payments />} /> */}
        </Routes>
    );
}
